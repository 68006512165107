/* eslint-disable */
import { useStore } from "effector-react";
import { $menuState } from "entities/menu/model/menu";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useGenerateRoutes } from "shared/hooks/useGenerateRouter";
import { menuHardcodes } from "shared/constants/menuHardcode";
import React from "react";
import LoaderPage from "shared/ui/LoaderPage";

export const Routing = React.memo(() => {
  const { menu } = useStore($menuState);
  const { defaultRoutes } = useGenerateRoutes([...(menu || []), ...((menu || []).length && menuHardcodes || [])]);

  if (!defaultRoutes.length) return <LoaderPage />

  const router = createBrowserRouter(defaultRoutes);

  return (
    <RouterProvider router={router} />
  );
});
