import { Routing } from "pages";
import { withProviders } from "./withProvider";
import { Suspense, useEffect } from "react";
import LoaderPage from "shared/ui/LoaderPage";
import { HelmetProvider } from 'react-helmet-async';
import { menuFx } from "entities/menu/model/menu";
import { AppStyles } from "./style";

const App = () => {

  useEffect(() => {
    menuFx()
  }, [])

  return (
    <HelmetProvider>
      <div className={AppStyles}>
        <Suspense fallback={<LoaderPage />}><Routing /></Suspense>
      </div>
    </HelmetProvider>

  );
}

export default withProviders(App);