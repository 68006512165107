
import { useStore } from 'effector-react';
import { getGridsFx } from 'entities/login-slider/model/effects/sliderEffects';
import { $gridState } from 'entities/login-slider/model/sliderModel';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async'
import { EXTERNAL_GATEWAY_URL_PREFIX } from 'shared/constants/generic';
import { BASE_URL } from 'shared/constants/genericApiRoutes';
import { getDataBySectionAndWidget, stripHTMLreg } from 'shared/helpers/genericHelper';

const PageMetaData = ({ menuItem }: any) => {
    let description = menuItem.metaDescription ? menuItem.metaDescription : menuItem.description || "";
    let metaTitle = menuItem.metaTitle ? menuItem.metaTitle : menuItem.title || ""
    const data: any = useStore($gridState).grids

    useEffect(() => {
        if (menuItem && menuItem.gridId) {
            getGridsFx(menuItem.gridId)
        }
    }, [menuItem])

    return (
        <>
            <Helmet>
                <title>{menuItem && menuItem.title}</title>
                {description && <meta name="og:description" content={description.replace(stripHTMLreg, "")} />}
                {metaTitle && <meta property="og:title" content={metaTitle} />}
                {data?.length && (getDataBySectionAndWidget(1, 1, data, "PHOTO","banner").value || getDataBySectionAndWidget(1, 1, data, "PHOTO","banner-promo").value) ?
                    <meta name="thumbnail" content={BASE_URL + EXTERNAL_GATEWAY_URL_PREFIX +
                        getDataBySectionAndWidget(1, 1, data, "PHOTO", "banner").value || getDataBySectionAndWidget(1, 1, data, "PHOTO","banner-promo").value}
                    /> : ""}
                {data?.length && (getDataBySectionAndWidget(1, 1, data, "PHOTO","banner").value || getDataBySectionAndWidget(1, 1, data, "PHOTO","banner-promo").value) ?
                    <meta property="og:image" content={BASE_URL + EXTERNAL_GATEWAY_URL_PREFIX +
                        getDataBySectionAndWidget(1, 1, data, "PHOTO", "banner").value || getDataBySectionAndWidget(1, 1, data, "PHOTO","banner-promo").value}
                    /> : ""
                }
            </Helmet>
        </>
    )
}

export default PageMetaData