import { createStore, createEffect, createEvent } from 'effector';
import { ApiError } from 'exceptions';
import { getMenu } from 'shared/api/menuApi';
import { menuHardcodes } from 'shared/constants/menuHardcode';
import { RouteStoreItem } from 'shared/hooks/useGenerateRouter';

interface MenuState {
	menu: any;
	footerMenu: any;
	error: any;
}

export const $menuState = createStore<MenuState>({
	menu: [],
	footerMenu: [],
	error: '',
});
export const $menuPaths = createStore<Record<string, RouteStoreItem>>({});

export const updatePaths = createEvent<Record<string, RouteStoreItem>>()

export const menuFx = createEffect(async () => {
	try {
		const { menu } = $menuState.getState();
		if (menu?.length) return
		const response = await getMenu();
		if (response?.length) {
			response.push(...menuHardcodes)
			return response
		}
		throw ApiError.UnauthorizedError()
	} catch (error) {
		throw ApiError.UnauthorizedError()
	}
});

$menuPaths.on(updatePaths, (_, newpaths) => newpaths);
$menuState
	.on(menuFx.done, (state, { result }) => ({
		...state,
		error: null,
		menu: result
	}))
	.on(menuFx.fail, (state, { error }) => ({
		...state,
		error: error.message,
		menu: null,
		footerMenu: null,
	}));
