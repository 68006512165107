export const menuHardcodes = [{
  menuId: 3529,
  label: "Waveum",
  menuType: "PAGE",
  canonicalUrl: "",
  brandId: 21,
  language: "it_IT",
  productType: "S",
  key: "tLogin",
  title: "Waveum"
}]