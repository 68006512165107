import { updatePaths } from "entities/menu/model/menu";
import { LazyExoticComponent, lazy } from "react"
import { Navigate, RouteObject } from "react-router"
import WrapperMetadata from "layout/metadataWrapper";
import { getLangPrefix } from "shared/helpers/genericHelper"

const OldLoginPage = lazy(() => import("pages/OldLoginPage/OldLoginPage"));
const OldPasswordReset = lazy(() => import("pages/OldPasswordRest/OldPasswordRest"));
const OldRegister = lazy(() => import("pages/OldRegister/OldRegister"));
const NavbarPage = lazy(() => import("pages/NavbarPage/NavbarPage"))

export type RouteStoreItem = {
  path: string,
  data?: Record<string, number | string | boolean>
}

const langPrefix = '/:ln'

type RouteComponent = {
  component: LazyExoticComponent<any>,
  props: any
}

const componentWithKeys: Record<string, RouteComponent> = {
  home: { component: OldLoginPage, props: null },
  register: { component: OldRegister, props: null },
  reset: { component: OldPasswordReset, props: null },
  tLogin: { component: NavbarPage, props: null }
}

const defaultRoutes: RouteObject[] = [];

const menuPaths: Record<string, RouteStoreItem> = {};

export const useGenerateRoutes = (menu: Record<string, string>[]) => {

  const setRoutes = () => {
    defaultRoutes.push({
      path: "/",
      element: <Navigate to={getLangPrefix()} replace />,
    })

    menu.forEach((menuItem) => {
      const Component = componentWithKeys[menuItem.key].component;
      menuPaths[menuItem.key] = {
        path: getLangPrefix() + menuItem.canonicalUrl,
        data: menuItem
      };

      defaultRoutes.push({
        path: `${langPrefix}${menuItem.canonicalUrl}`,
        element: <WrapperMetadata menuItem={menuItem}><Component /></WrapperMetadata>,
      })
    })

    defaultRoutes.push({
      path: '*',
      element: <Navigate to={getLangPrefix()} replace />,
    });

    updatePaths(menuPaths);
  }

  if (!defaultRoutes.length && menu.length) {
    setRoutes()
  }

  return { defaultRoutes }
}