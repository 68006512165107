import PageMetaData from "./PageMetaData"


const MetadataWrapper = ({ children, menuItem }: any) => {
    return (
        <>
            <PageMetaData menuItem={menuItem} />
            {children}
        </>
    )
}

export default MetadataWrapper